var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"State Ranking","active":""}})]}},{key:"dropdown-options",fn:function(ref){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]}},{key:"content",fn:function(){return [_c('table-layout',{ref:"table-layout",attrs:{"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: true },"sorting":_vm.table.sorting,"items":_vm.table.items,"loading":_vm.table.loading,"modal-id":"table-instrument-modal","is-searchable":true,"export-exclude-fields":[
                      'id',
                      'student.id',
                      'festival.id',
                      'form.id',
                      'instrument.id',
                      'instrument.slug' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"form-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"form-input","options":_vm.options.instruments.items,"loading":_vm.options.instruments.loading,"reduce":function (instrument) { return instrument.name; },"label":"name","select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Student Grade","label-for":"grade-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"grade-input","options":['10', '11'],"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.grade.value),callback:function ($$v) {_vm.$set(_vm.table.filters.grade, "value", $$v)},expression:"table.filters.grade.value"}})],1)],1)],1)]},proxy:true},{key:"cell(name)",fn:function(ref){
                  var data = ref.data;
return [_c('b-media',{attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[(_vm.hasCustomAvatar(data.item.avatar))?_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('b-img',{attrs:{"fluid":"","fluid-grow":"","src":data.item.avatar.src,"alt":data.item.avatar.name}})],1):_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'management-instrument', params: { id: data.item.id, slug: data.item.slug } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)],1)]}},{key:"cell(teacher.name)",fn:function(ref){
                  var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.teacher.name.first)+" "+_vm._s(data.item.teacher.name.last)+" ")]}},{key:"cell(student.name)",fn:function(ref){
                  var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.student.name.first)+" "+_vm._s(data.item.student.name.last)+" ")]}},{key:"cell(festival.name)",fn:function(ref){
                  var data = ref.data;
return [(data.item.festival)?_c('div',[_vm._v(" "+_vm._s(data.item.festival.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Festival ")])]}},{key:"cell(instrument.name)",fn:function(ref){
                  var data = ref.data;
return [(data.item.instrument)?_c('div',[_vm._v(" "+_vm._s(data.item.instrument.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Instrument ")])]}},{key:"cell(selection.ensemble.name)",fn:function(ref){
                  var data = ref.data;
return [(data.item.selection && data.item.selection.ensemble)?_c('div',[_vm._v(" "+_vm._s(data.item.selection.ensemble.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Ensemble ")])]}},{key:"cell(student.school.name.legal)",fn:function(ref){
                  var data = ref.data;
return [(data.item.student && data.item.student.school && data.item.student.school.name)?_c('div',[_vm._v(" "+_vm._s(data.item.student.school.name.legal)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No School ")])]}},{key:"cell(student.school.zone.name)",fn:function(ref){
                  var data = ref.data;
return [(data.item.student && data.item.student.school && data.item.student.school.zone )?_c('div',[_vm._v(" "+_vm._s(data.item.student.school.zone.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Zone ")])]}},{key:"cell(createdAt)",fn:function(ref){
                  var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.createdAt,"no-text":""}})]}},{key:"cell(updatedAt)",fn:function(ref){
                  var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.updatedAt,"no-text":""}})]}},{key:"cell(row-options)",fn:function(ref){
                  var data = ref.data;
return [_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-student', params: { id: data.item.student.id } }}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Student")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-application', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Application")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-selection', params: { slug: (data.item.instrument ? data.item.instrument.slug : 'no-instrument'), id: (data.item.instrument ? data.item.instrument.id : null) } }}},[_c('feather-icon',{attrs:{"icon":"CheckSquareIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Selection")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-scores', query: _vm.scoresQuery(data.item)}}},[_c('feather-icon',{attrs:{"icon":"HashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Manage Scores")])],1)]}}])})]},proxy:true},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":""}},[_vm._v(_vm._s(_vm.table))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }