<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="State Ranking" active/>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <table-layout ref="table-layout"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                    :sorting="table.sorting"
                    :items="table.items"
                    :loading="table.loading"
                    modal-id="table-instrument-modal"
                    :is-searchable="true"
                    :export-exclude-fields="[
                        'id',
                        'student.id',
                        'festival.id',
                        'form.id',
                        'instrument.id',
                        'instrument.slug',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col>
              <b-form-group label="Instrument" label-for="form-input">
                <v-select id="form-input"
                          v-model="table.filters.instrument.value"
                          :options="options.instruments.items"
                          :loading="options.instruments.loading"
                          :reduce="instrument => instrument.name"
                          label="name"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Student Grade" label-for="grade-input">
                <v-select id="grade-input"
                          v-model="table.filters.grade.value"
                          :options="['10', '11']"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-instrument', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>


        <!-- Column: State - Enabled -->
        <template #cell(teacher.name)="{data}">
          {{ data.item.teacher.name.first }} {{ data.item.teacher.name.last }}
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(student.name)="{data}">
          {{ data.item.student.name.first }} {{ data.item.student.name.last }}
        </template>


        <template #cell(festival.name)="{data}">
          <div v-if="data.item.festival">
            {{ data.item.festival.name }}
          </div>
          <div v-else class="text-danger">
            No Festival
          </div>
        </template>

        <template #cell(instrument.name)="{data}">
          <div v-if="data.item.instrument">
            {{ data.item.instrument.name }}
          </div>
          <div v-else class="text-danger">
            No Instrument
          </div>
        </template>

        <template #cell(selection.ensemble.name)="{data}">
          <div v-if="data.item.selection && data.item.selection.ensemble">
            {{ data.item.selection.ensemble.name }}
          </div>
          <div v-else class="text-danger">
            No Ensemble
          </div>
        </template>

        <template #cell(student.school.name.legal)="{data}">
          <div v-if="data.item.student && data.item.student.school && data.item.student.school.name">
            {{ data.item.student.school.name.legal }}
          </div>
          <div v-else class="text-danger">
            No School
          </div>
        </template>

        <template #cell(student.school.zone.name)="{data}">
          <div v-if="data.item.student && data.item.student.school && data.item.student.school.zone ">
            {{ data.item.student.school.zone.name }}
          </div>
          <div v-else class="text-danger">
            No Zone
          </div>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(createdAt)="{data}">
          <last-modified :date="data.item.createdAt" no-text />
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(updatedAt)="{data}">
          <last-modified :date="data.item.updatedAt" no-text />
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'all-state-student', params: { id: data.item.student.id } }">
            <feather-icon icon="UserIcon" />
            <span class="align-middle ml-50">View Student</span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'all-state-application', params: { id: data.item.id } }">
            <feather-icon icon="ClipboardIcon" />
            <span class="align-middle ml-50">View Application</span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'all-state-selection', params: { slug: (data.item.instrument ? data.item.instrument.slug : 'no-instrument'), id: (data.item.instrument ? data.item.instrument.id : null) } }">
            <feather-icon icon="CheckSquareIcon" />
            <span class="align-middle ml-50">View Selection</span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'all-state-scores', query: scoresQuery(data.item)}">
            <feather-icon icon="HashIcon" />
            <span class="align-middle ml-50">Manage Scores</span>
          </b-dropdown-item>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug collapsed>{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import InstrumentModal from '@/views/management/instruments/InstrumentModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import { listApplications, listInstruments } from '@/graphql/queries/report-state-ranking';
import LastModified from '@/components/LastModified.vue';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'StateRanking',
  components: {
    LastModified,
    TableLayout,
    PageLayout,
    InstrumentModal,
    vSelect
  },
  mixins: [ avatar, notify, print, settingsMixin ],
  data() {
    return {
      table: {
        fields: [
          {
            key: 'festival.name',
            label: 'Festival',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'ranking.local',
            label: 'Local Rank',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'selection.ranking.state',
            label: 'State Rank',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.name.first',
            label: 'First Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.name.last',
            label: 'Last Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.grade',
            label: 'Grade',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'ranking.score',
            label: 'Score',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'selection.ensemble.name',
            label: 'Ensemble',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'selection.part',
            label: 'Part',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.school.name.legal',
            label: 'School',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.school.zone.name',
            label: 'Zone',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          instrument: { key: 'instrument.name', value: null },
          grade: { key: 'student.grade', value: null },
        },
        sorting: {
          by: null,
          comparator: (a, b) => this.compareByInstrumentAndStateRank(a, b),
          comparatorKey: 'isr',
          comparators: [
            { key: 'isr', text: 'Instrument/State Rank', comparator: (a, b) => this.compareByInstrumentAndStateRank(a, b) },
            { key: 'sri', text: 'State Rank/Instrument', comparator: (a, b) => this.compareByStateRankAndInstrument(a, b) },
            { key: 'fsl', text: 'Festival/State & Local Rank', comparator: (a, b) => this.compareByFestivalStateLocal(a, b) },
            { key: 'fls', text: 'Festival/Local & State Rank', comparator: (a, b) => this.compareByFestivalLocalState(a, b) }
          ]
        },
        items: [],
        loading: true
      },
      options: {
        instruments: {
          items: [],
          loading: true
        }
      },
      icon: 'fas fa-music',
      debug: {}
    }
  },
  computed: {
    filterFormOptions() {
      return [...new Set(this.table.items.map(item => item.form?.name || null).filter(name => name !== null))].sort((a, b) => a.localeCompare(b))
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    },
  },
  async mounted() {
    this.$refs.layout.state.loading = false
    await this.listInstruments();
    await this.listApplications();
  },
  methods: {
    async refresh() {
      this.table.loading = true
      await this.listApplications()
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.options.instruments.items = response.data.listInstruments.items.sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))

      this.options.instruments.loading = false
    },
    async listApplications(nextToken, pagedApplications) {
      const applications = pagedApplications || []
      const input = {
        filter: {
          applicationSelectionId: { attributeExists: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items);

      if(response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.table.items = applications.filter(app => app.selection && app.selection?.ranking?.state > 0).map(app => ({
          id: app.id,
          festival: app.festival,
          form: app.form,
          instrument: app.instrument,
          ranking: app.ranking,
          student: app.student,
          selection: app.selection,
        }))
        this.table.loading = false
      }
    },
    scoresQuery(item) {
      return {
        filters: btoa(JSON.stringify({
          festival: null,
          form: null,
          instrument: null,
          student: item.student.id
        }))
      }
    },
    compareByInstrumentAndStateRank(a, b) {
        return a?.instrument?.order - b?.instrument?.order
            || a?.selection?.ranking?.state - b?.selection?.ranking?.state
            || a?.ranking?.local - b?.ranking?.local
    },
    compareByStateRankAndInstrument(a, b) {
        return a?.selection?.ranking.state - b.selection?.ranking?.state
            || a?.instrument?.order - b?.instrument?.order
            || a?.ranking?.local - b?.ranking?.local
    },
    compareByFestivalStateLocal(a, b) {
      return a?.festival?.name?.localeCompare(b?.festival?.name, undefined, { numeric: true, sensitivity: 'base' })
          || a?.selection?.ranking?.state - b?.selection?.ranking?.state
          || a?.ranking?.local - b?.ranking?.local
    },
    compareByFestivalLocalState(a, b) {
      return a?.festival?.name?.localeCompare(b?.festival?.name, undefined, { numeric: true, sensitivity: 'base' })
          || a?.ranking?.local - b?.ranking?.local
          || a?.selection?.ranking?.state - b?.selection?.ranking?.state
    },
  }
}
</script>

<style lang="scss">

</style>
